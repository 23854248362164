'use strict';

/**
 * Append URL parameters to the article images based on site preference configuration
 * @param {Object} currentPdArticleTile JQuery Element
 * @param {Object} articleImage object with src values for desktop, tablet, and mobile
 * @returns articleImage object with DIS values appended, if the site preference is set properly
 */
function appendDisConfig(currentPdArticleTile, articleImage) {
    if (articleImage && articleImage.src && articleImage.src.desktop && articleImage.src.tablet && articleImage.src.mobile) {
        let features;
        let parsedArticleBlockDisConfig;
        try {
            features = JSON.parse(document.querySelector('body').dataset.features);
            parsedArticleBlockDisConfig = JSON.parse(features.articleBlockDisConfig);
        } catch (e) {
            console.error('Error parsing articleBlockDisConfig JSON:' + e.message);
            return articleImage;
        }
        if (parsedArticleBlockDisConfig && currentPdArticleTile.closest('.featured-article')) {
            // if it's the featured article, we have different configs to apply
            try {
                articleImage.src.desktop = articleImage.src.desktop + "?sw=" + parsedArticleBlockDisConfig.featured.desktop;
                articleImage.src.tablet = articleImage.src.tablet + "?sw=" + parsedArticleBlockDisConfig.featured.tablet;
                articleImage.src.mobile = articleImage.src.mobile + "?sw=" + parsedArticleBlockDisConfig.featured.mobile;
            } catch (e) {
                console.error('Error assigning articleBlockDisConfig JSON to featured image:' + e.message);
            }
        } else if (parsedArticleBlockDisConfig) {
            try {
                articleImage.src.desktop = articleImage.src.desktop + "?sw=" + parsedArticleBlockDisConfig.standard.desktop;
                articleImage.src.tablet = articleImage.src.tablet + "?sw=" + parsedArticleBlockDisConfig.standard.tablet;
                articleImage.src.mobile = articleImage.src.mobile + "?sw=" + parsedArticleBlockDisConfig.standard.mobile;
            } catch (e) {
                console.error('Error assigning articleBlockDisConfig JSON to standard image:' + e.message);
            }
        }
    }
    return articleImage;
}

/**
 * Setup feature toggle functionality
 */
function initArticleTile() {
    var $pdArticles = $('.pd.article-data');

    if ($pdArticles.length) {
        $pdArticles.each(function () {
            var articleData;
            try {
                articleData = JSON.parse($(this).text());
            } catch (e) {
                return;
            }

            var $articleTile = $(this).siblings('.article-tile');
            var articleImage;
            var articleImageAlt;
            var articleTitle;
            var articleDesc;
            var articleBadges;
            var textBadgesExist = false;
            var catBadgesExist = false;
            var articleImageWidth;
            var articleImageHeight;

            if (articleData && articleData.custom) {
                articleImage = articleData.custom.articleImage ? articleData.custom.articleImage : null;
                articleImageAlt = articleData.custom.articleImageAlt ? articleData.custom.articleImageAlt : null;
                articleTitle = articleData.custom.articleTitle ? articleData.custom.articleTitle : null;
                articleDesc = articleData.custom.articleDesc ? articleData.custom.articleDesc : null;
                articleImageWidth = articleData.custom.articleImageWidth ? articleData.custom.articleImageWidth : null;
                articleImageHeight = articleData.custom.articleImageHeight ? articleData.custom.articleImageHeight : null;
                if (articleData.custom.articleBadges) {
                    articleBadges = articleData.custom.articleBadges;
                }
                articleImage = appendDisConfig(this, articleImage);
            }

            $articleTile.removeClass('template').addClass('tile-' + articleData.id);

            if (articleImage) {
                $articleTile.find('.content-thumbnail-image').parents('picture').prepend('<source data-srcset="' + articleImage.src.tablet  + '" width="' + articleImageWidth + '" height="' + articleImageHeight + '" media="(min-width: 768px)"/>');
                $articleTile.find('.content-thumbnail-image').parents('picture').prepend('<source data-srcset="' + articleImage.src.desktop + '" width="' + articleImageWidth + '" height="' + articleImageHeight + '" media="(min-width: 1024px)"/>');
                $articleTile.find('.content-thumbnail-image').attr({
                    'data-src': articleImage.src.mobile,
                    width: articleImageWidth,
                    height: articleImageHeight
                }).addClass('lazyload');
            }

            if (articleImageAlt) {
                $articleTile.find('.content-thumbnail-image').attr('alt', articleImageAlt);
            }

            if (articleTitle) {
                $articleTile.find('.content-thumbnail-title .content-thumbnail-title-text').text(articleTitle);
            }

            if (articleDesc) {
                $articleTile.find('.content-thumbnail-description').append('<p>' + articleDesc + '</p>');
            }

            if (articleBadges) {
                if (articleBadges.catBadges && articleBadges.catBadges.length > 0) {
                    catBadgesExist = true;
                    for (let i = 0; i < articleBadges.catBadges.length; i++) {
                        $articleTile.find('.content-thumbnail-badges').append('<li role="presentation"><a href="' + articleBadges.catBadges[i].url + '">' + articleBadges.catBadges[i].name + '</a></li>');
                    }
                }

                if (articleBadges.textBadges && articleBadges.textBadges.length > 0) {
                    textBadgesExist = true;
                    for (let i = 0; i < articleBadges.textBadges.length; i++) {
                        $articleTile.find('.content-thumbnail-badges').append('<li role="presentation">' + articleBadges.textBadges[i] + '</li>');
                    }
                }

                if (!catBadgesExist && !textBadgesExist) {
                    $articleTile.find('.content-thumbnail-badges').remove();
                }
            }

            if (!articleImage && !catBadgesExist && !textBadgesExist) {
                $articleTile.find('.content-thumbnail-figure').remove();
            }
        });
    }
}

/**
 * Init Article Blocks
 */
function initArticleBlock() {
    var $pdArticleBlock = $('.article-tiles-block');

    if ($pdArticleBlock.length) {
        $pdArticleBlock.each(function () {
            $(this).find('.view-all-articles').on('click', function () {
                $(this).parents('.article-tiles-block').removeClass('hiding-tiles');
                $(this).parents('.article-tiles-block').find('.article-tiles-summary').remove();
            });
        });
    }
}

$(document).ready(function () {
    initArticleTile();
    initArticleBlock();
});

// $(window).smartresize(function () {
//     initArticleTile();
// });
